body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#rem-pad {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.bg-black {
  background-color: black;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a {
  cursor: pointer !important;
}

/* Nav */
#google_translate_element span {
  display: none;
}

#google_translate_element {
  width: 110px !important;
  overflow: hidden;
  height: 30px !important;
  display: flex;
  align-items: center;
  margin-left: 1rem;
  
}

.nav-link {
  /* border-bottom: 1px solid orange; */
  margin: 0 0.5rem;
}
.active.nav-link {
  /* border-bottom: 1px solid orange; */
  /* background-color: orange; */
}
.nav-link{
  color: white !important;
}


/* CustomStyles.css */
.custom-container {
  /* display: flex; */
  justify-content: flex-start;
  align-items: center;
  min-height: 70vh;
  text-align: left;
  margin-top: 12%;
  /* color: blue;  */
}

.custom-container h1 {
  font-family: monospace; /* Choose a better font */
  /* font-size: 32px; */
  /* margin-bottom: 0.5rem; */
  color: white !important;
  position: relative;
  z-index: 1; 
}

.custom-container p small {
  font-size: 1.1rem;
  margin-left: 1%;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif; /* Choose a better font */
  /* Adjust font size */
}

.custom-container form {
  /* display: flex; */
  /* flex-direction: column; */

  /* align-items: flex-start; */
  /* margin-top: 1rem; */
}


.custom-container input[type="text"] {
  padding: 0.5rem;
  /* margin-bottom: 0.5rem; */
  /* width: 100%; */

  max-width: 200px; 
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 4px;
}

.custom-container button[type="submit"] {
  padding: 0.5rem 1rem;
  border: none;
  margin-left: 1%;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.custom-container button[type="submit"]:hover {
  background-color: #0056b3;
  
}

/* end of nav */

/* footer */
footer {
  background-color: rgb(2, 14, 14);
}

footer a {
  color:white;
  font-size: 1.0rem;
  font-weight: 600;
}

footer .links {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  border-bottom: 1px solid rgba(51, 51, 51, 0.747);
  padding: 1rem;
}

@media (min-width: 769px) {
  footer .links {
    justify-content: space-between;
  }

  footer .links > div {
    margin: 2rem;
  }
}
h3 ,h5{
  font-family: 'Cambria, Cochin, Georgia, Times, serif';
}

footer > p {
  border-bottom: 2px solid rgba(51, 51, 51, 0.747);
  background-color: black;
  color: white;
}
/* End of footer */

/* Hero  */
.top {
  /* margin-top: 1rem; */
}
.hero {
  background-image: url("./images/vinc1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  min-height: 80vh;
}
.hero .texts {
  background-color: rgba(40, 39, 39, 0.775);
  color: white;
  /* font-size: 1.5rem; */
  height: 100%;
  min-height: 80vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.hero .texts h1 {
  /* font-weight: 400; */
  font-size: 2.5rem;
  margin-top: 4rem;
}

@media (min-width: 769px) {
  .hero .texts h1 {
    font-size: 4rem;
    /* margin-top: 15%; */
  }
}
.hero form {
  /* background-color: white; */
  /* width: fit-content; */
  /* text-align: center; */
  /* display: block; */
  margin-top: 2%;
  /* padding: 2rem; */
}

.hero input {
  padding: 0 0.5rem;
  width: 100%;
  height: 3rem;
  margin-bottom: 0.5rem;
}

.hero button {
  /* width: 100%; */
  /* background-color: orange; */
  border: none;
  padding: 0.5rem;
  letter-spacing: 1px;
  font-family: monospace;
}
.hero button:hover {
  border-bottom: 1px solid black;
}

/* end of hero */

/* Wedo */
.wedo h2,
.sample-p,
.services-h2 {
  margin-top: 4rem;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-size: 2rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.864);
}

.blue {
  background-color: #f1f9fc;
  display: flex;
  gap: 10%;
}
.wedo h4 {
  margin: 0 0 1rem 0;
  font-size: 2rem;
  
}
.wedo .our-card {
  /* box-shadow: 0 0 7px rgba(0, 0, 0, 0.357); */
  margin: 1rem 0;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  height: 570px;
  color: rgb(106, 103, 103);
  /* border: 1px solid orange; */
  transition: all 0.2s;

}
/* @keyframes roll {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
} */

.rolling-animation {
  animation: roll 1s linear; /* Adjust timing as needed */
}


.roll-in {
  transform: rotateY(0deg); /* Rotate to 0 degrees when in view */
}

.roll-out {
  transform: rotateY(90deg); /* Optional: Rotate to 90 degrees when out of view */
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.service-card {
  opacity: 0; /* Start with opacity 0 */
}

.our-card img {
  opacity: 0;
  /* transition: opacity 1s ease-in-out; */
}
.blue{
  opacity: 0;
  /* transition: opacity 1s ease-in-out; */
}
.blue {
  opacity: 1;
  animation: fadeIn 1s ease-out;
  
}


.scrollTop {
  position: fixed;
  right: 20px; /* Adjust this value to move the button left or right */
  bottom: 100px; /* Adjust this value to move the button up or down */
  z-index: 1000; /* Ensure it is above other elements */
  background-color:  #589ae1;
  color: white;
  border: none;
  padding: 20px;
  border-radius: 5px;
  cursor: pointer;
  display: none;
  align-items: center;
  justify-content: center;
}

.scrollTop:hover {
  background-color: #002d5b;
}

.carousel .slide, 
.carousel .carousel-slider, 
.carousel .carousel .control-arrow, 
.carousel .carousel .control-next.control-arrow:before, 
.carousel .carousel .control-prev.control-arrow:before {
  background: rgb(237, 237, 237) !important; /* Removes the white background */
}


.fade-in img {
  opacity: 1;
  animation: fadeIn 1s ease-out;
}


/* .fade-in {
  opacity: 1; 
  transition: none; 
} */
.wedo .our-card:hover {
  transform: scale(1.02);
  z-index: 999;
}


@media (min-width: 996px) {
  .wedo .our-card {
    height: 400px;
  }
}

@media (max-width: 995px) and (min-width: 818px) {
  .wedo .our-card {
    height: 500px;
  }
 
}

@media (max-width: 768px) {
  .wedo .our-card {
    height: max-content;
  }
}


@media (min-width: 1096px) {
  .wedo .our-card {
    height: 350px;
  }
}
/* end of wedo */

/* auth */

.form-container {
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  margin: auto;

  box-shadow: 0 0 20px rgb(241, 234, 234);
  background-color: rgb(255, 227, 176);
}

.text-container{
  background-color: #002d5b;
}
.form-container form {
  padding-top: 1rem !important;
  color: rgb(93, 93, 93);
}

.form-container form > * {
  margin: 0.7rem;
}

.form-container form > button[type="submit"] {
  margin: auto;
  display: block;
  margin-bottom: 3rem;
}
/* .double-line {
  border-top: 10px double #184e87;
  width: 10%;
  margin: 0 auto; 
  box-sizing: border-box; 
  margin-bottom: 10px; 
} */

.double-line {
  border-top: 15px double #184e87;
  width: 10%;
  margin: 20px auto; /* Center the double line */
}

.samples .slide {
  display: flex;
  justify-content: space-between;
  
}

.image-pair {
  display: flex;
  gap: 5%; 
}

.image-container {
  flex: 1;
  max-width: 40%; /* Ensures two images fit side by side */
}


/* hr{
  font-weight: 600;
  color: #184e87;
} */
th,
td {
  white-space: nowrap !important;
}

.sr-only {
  display: none;
}

/* End of auth */




/* about us */
.about p {
  font-size: 1.1rem;
}

.about h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  font-family:  Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  /* background-color: gray; */
  margin-bottom: 2rem;
  /* color: white !important; */
  font-size: 5rem;
}

.about .first-p::first-letter {
  font-size: 3rem;
  color: orange;
}

.about h3 {
  margin-top: 5rem;
}
/* End of about */

/* COntact us */
.contact {
  display: flex;
  flex-wrap: wrap;
}
footer .text-footer{
  font-family: 'Cambria, Cochin, Georgia, Times, serif';
}

.contact > * {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  font-family: monospace;
  box-shadow: 0 0 10px rgb(238, 234, 234);
  /* border: 1px solid orange; */
  padding: 1rem;
}
.contact > *:hover {
  transform: scale(1.02);
}
/* End of contact us */

/* Samples */
.samples {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}

.sample-p {
  margin-top: 3rem;
  margin: 2rem 0;
  font-size: 2.5rem;
  font-weight: 800;
  color: rgba(0, 0, 0, 0.864);
}

.samples > div {
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.357);
  transition: all 0.3s;
}

.samples > div:hover {
  transform: scale(1.02);
  z-index: 999;
}
/* End of samples */

/* Services */

.services > div {
  display: flex;
  gap: 1rem;
  margin: 1.5rem 0;
}
.services > div div:last-of-type h4 {
  color: orange;
  font-family: monospace;
  font-weight: 600;
  font-size: 2rem;
}

@media (max-width: 768px) {
.blue{
  display: block;
}

.text-center{
  font-size: 24px;
}

.image-container img {
  height: 100px !important;
  width: auto; /* Maintain aspect ratio */
}
  .hero .texts h1 {
    font-size: 2rem;
  }

  p.text-center{
  font-size: 16px;
 }

  .custom-container h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .custom-container p {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  .custom-container input[type="text"] {
    font-size: small;
  }

  .custom-container button[type="submit"] {
   font-size: medium;
  }


}
/* End of services */

/* contact page */
/* CustomCarousel.css */

.contact-background {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-image: url('../src/images/international_2.jpg'); /* Adjust the path as necessary */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.contact-background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); 
  z-index: 0; /* Ensure the overlay is behind the content */
}

.contact {
  position: relative; /* Ensure the content is on top of the overlay */
  z-index: 1;
  background-color: transparent;
  font-family: 'monospace' /* Make the background transparent */
}

.contactForm {
  position: relative;
  /* background-color: rgb(123, 142, 147) !important ;Ensure the form container has a background color for readability */
  z-index: 1; 
  /* Ensure the form container is above the overlay */

}
.form-control{
  font-family: 'monospace'
}
.form-container,
.contact {
  margin-top: -35px; /* Adjust as needed to position content properly */
  background-color: transparent; 
  color: white !important;/* Make the background transparent */
  font-weight: 800;
  font-size: 20px;
  font-family: 'monospace'
}

.contact a,
.contact p,
.contact h3,
.form-container
 {
  color: white !important; /* Set text color to white to contrast with background */
}

.form-label{
  color:  rgb(93, 92, 92) !important;
}
.text-container{
  color: rgb(93, 92, 92) !important;
}